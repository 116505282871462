import React from "react";
import "./SenseBank.scss";
import SenseBankIllustration from "../SenseBankIllustration/SenseBankIllustration";
import { Trans, useTranslation } from "react-i18next";
import FormatPhone from "../../../../../components/ui/components/FormatPhone/FormatPhone";



const SenseBank =  () => {
  const [t] = useTranslation();

  return (
    <div className="sense-bank">
      <div className="sense-bank__logo">
        <SenseBankIllustration/>
        <div>{t('Банк держави майбутнього')}</div>
      </div>
      <div className="sense-bank__text">
        <Trans i18nKey="sense_bank_message">
          Ставай <a href="https://sensebank.ua/sense-superapp?registration">клієнтом банку</a> та користуйся
          перевагами програми лояльності Cash’u Club
        </Trans>


      </div>
    </div>
  )
}

export default SenseBank;