import React from "react";


const SenseBankIllustration = ()=>{
  return (
    <svg width="180" height="25" viewBox="0 0 180 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10070_12733)">
        <path d="M26.8161 21.8244C23.5316 21.8244 21.0907 19.5524 20.7758 16.3353H33.7C35.1285 10.6437 31.9566 6.38062 26.2537 6.38062C21.4281 6.38062 17.48 10.5425 17.48 15.6379C17.48 20.9134 21.4956 24.8952 26.8048 24.8952C30.8205 24.8952 33.9024 22.8368 35.2973 19.6873L32.6202 18.0113C31.7766 20.2835 29.7518 21.8244 26.8161 21.8244ZM26.2537 9.35015C29.2232 9.35015 30.9667 11.0936 30.8991 13.6807H20.9445C21.5407 10.9587 23.4978 9.35015 26.2537 9.35015Z" fill="#2722FF"/>
        <path d="M46.6238 6.38062C44.2841 6.38062 42.3269 7.46044 40.9322 8.9677V6.89803H37.7827V24.3666H40.9322V15.1768C40.9322 12.106 43.0244 9.45138 46.4551 9.45138C49.3234 9.45138 51.0331 11.2736 51.0331 13.8944V24.3778H54.1826V13.6132C54.1714 8.95646 51.2693 6.38062 46.6238 6.38062Z" fill="#2722FF"/>
        <path d="M83.5186 24.8952C87.5342 24.8952 90.6163 22.8368 92.011 19.6873L89.3227 18.0113C88.4791 20.2835 86.4545 21.8244 83.5186 21.8244C80.2342 21.8244 77.7932 19.5524 77.4783 16.3353H90.4026C91.831 10.6437 88.6591 6.38062 82.9562 6.38062C78.1308 6.38062 74.1826 10.5425 74.1826 15.6379C74.1939 20.9134 78.2094 24.8952 83.5186 24.8952ZM82.9562 9.35015C85.9257 9.35015 87.6692 11.0936 87.6018 13.6807H77.647C78.2432 10.9587 80.2003 9.35015 82.9562 9.35015Z" fill="#2722FF"/>
        <path d="M64.3507 9.37268C66.3755 9.37268 68.1189 9.90135 70.1211 11.4649L70.2111 11.1612L71.8871 6.58312L69.3 5.7395L68.8275 7.60671C67.5565 6.94306 66.0379 6.40314 64.3845 6.40314C60.1552 6.40314 57.8155 8.36033 57.8155 11.2961C57.8155 14.1982 60.3689 15.6267 64.317 16.8865C67.534 17.8651 68.4451 18.8099 68.4451 19.721C68.4451 21.1833 67.0166 22.0269 64.9806 22.0269C62.3598 22.0269 60.1214 20.6997 58.9403 18.675L56.5332 20.6659C57.9955 23.0393 60.9312 24.929 64.8457 24.929C69.4574 24.929 71.6959 22.4881 71.6959 19.4061C71.6959 17.3815 70.3348 15.7055 65.1269 13.9844C62.7872 13.1858 61.0775 12.7247 61.0775 11.2287C61.0663 10.1488 61.7974 9.37268 64.3507 9.37268Z" fill="#2722FF"/>
        <path d="M7.82877 9.36171C9.85346 9.36171 11.5969 9.89037 13.5992 11.4538L13.6892 11.1502L15.3651 6.57213L12.778 5.72852L12.3055 7.59573C11.0345 6.93208 9.51602 6.39216 7.86252 6.39216C3.63318 6.39216 1.29354 8.34936 1.29354 11.2852C1.29354 14.1872 3.8469 15.6157 7.79503 16.8756C11.012 17.8541 11.9232 18.799 11.9232 19.7101C11.9232 21.1724 10.4946 22.016 8.45868 22.016C5.83784 22.016 3.59944 20.6887 2.41837 18.664L0 20.6549C1.46228 23.0283 4.39807 24.918 8.31245 24.918C12.9242 24.918 15.1627 22.4771 15.1627 19.3952C15.1627 17.3704 13.8016 15.6944 8.59366 13.9735C6.25402 13.1749 4.54429 12.7137 4.54429 11.2176C4.54429 10.1378 5.27543 9.36171 7.82877 9.36171Z" fill="#2722FF"/>
        <path d="M119.614 15.6607C119.614 20.9474 115.835 24.9517 110.785 24.9517C108.546 24.9517 106.577 23.9732 105.071 22.4658V24.4231H101.921V0.0256348H105.071V8.8555C106.577 7.34824 108.535 6.36964 110.785 6.36964C115.835 6.36964 119.614 10.3628 119.614 15.6607ZM116.329 15.6607C116.329 12.05 113.946 9.4629 110.65 9.4629C107.353 9.4629 104.97 12.0612 104.97 15.6607C104.97 19.2714 107.353 21.8584 110.65 21.8584C113.946 21.8584 116.329 19.2714 116.329 15.6607Z" fill="#2722FF"/>
        <path d="M139.389 21.2508C139.254 21.2508 139.141 21.2284 139.029 21.1946C138.68 21.0709 138.477 20.7672 138.477 20.3172L138.86 15.6942L139.017 14.0744L139.715 6.88685H136.588L136.352 9.57518C136.352 9.57518 136.352 9.56393 136.34 9.56393C136.329 9.54144 136.306 9.5077 136.296 9.4852C136.07 9.159 135.8 8.85529 135.508 8.59658C134.068 7.31429 132.268 6.49316 130.233 6.49316C125.227 6.49316 121.481 10.4526 121.481 15.6942C121.481 20.9359 125.239 24.8953 130.233 24.8953C132.415 24.8953 134.327 23.9616 135.812 22.5106C135.834 22.7244 135.856 22.8143 135.924 22.9831C135.936 23.0056 135.936 23.0168 135.936 23.0168C136.104 23.343 136.317 23.6242 136.599 23.8379C136.948 24.0967 137.376 24.2654 137.881 24.3441C138.073 24.3778 138.286 24.3892 138.5 24.3892H139.017H140.175V21.2621H139.389V21.2508ZM130.39 21.847C127.128 21.847 124.766 19.2711 124.766 15.6942C124.766 12.1173 127.128 9.54144 130.39 9.54144C133.652 9.54144 136.014 12.1173 136.014 15.6942C136.014 19.2711 133.652 21.847 130.39 21.847Z" fill="#2722FF"/>
        <path d="M152.346 6.38062C150.006 6.38062 148.049 7.46044 146.653 8.9677V6.89803H143.504V24.3666H146.653V15.1768C146.653 12.106 148.746 9.45138 152.176 9.45138C155.045 9.45138 156.754 11.2736 156.754 13.8944V24.3778H159.904V13.6132C159.893 8.95646 156.99 6.38062 152.346 6.38062Z" fill="#2722FF"/>
        <path d="M169.792 15.3457L178.836 6.89833H174.708L166.677 14.4459V0.0144043H163.538V24.3668H166.677V16.673L175.203 24.3668H179.781L169.792 15.3457Z" fill="#2722FF"/>
      </g>
      <defs>
        <clipPath id="clip0_10070_12733">
          <rect width="180" height="24.9711" fill="white" transform="translate(0 0.0144043)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
export default SenseBankIllustration