import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './HotelIndexPlaces.scss';

import Image from '../../../../../components/ui/components/Image/Image';
import Container from '../../../../../components/ui/layouts/Container/Container';
import { generateStaticLinkToHotelsSearch } from '../../../../../helpers/hotels';
import { isBronui, isSudtirol } from "../../../../../config";
import SenseBank from "../SenseBank/SenseBank";

const regionsSudtirol = {
  bolzano:{
    id: 263,
    name: 'Bolzano',
    coordinates: {
      latitude: '46.4949259',
      longitude: '11.3402543'
    }
  },
  merano:{
    id: 3,
    name: 'Merano',
    coordinates: {
      latitude: '46.6711489',
      longitude: '11.1525785'
    }
  },
  bressanone:{
    id: 161,
    name: 'Bressanone',
    coordinates: {
      latitude: '46.7104236',
      longitude: '11.6524696'
    }
  },
  brunico:{
    id: 214,
    name: 'Brunico',
    coordinates: {
      latitude: '46.7965374',
      longitude: '11.9381326'
    }
  }
}


const regions = {
    kyiv: {
        id: 1218,
        name: 'Київ',
        coordinates: {
            latitude: '50.4501000',
            longitude: '30.5234000'
        }
    },
    bukovel: {
        id: 13,
        name: 'Буковель Івано-Франківська область',
        coordinates: {
            latitude: '48.3623404',
            longitude: '24.4054980'
        }
    },
    yaremche: {
        id: 302,
        name: 'Яремче, Івано-Франківська область',
        coordinates: {
            latitude: '48.4815570',
            longitude: '24.5819174'
        }
    },
    lviv: {
        id: 54,
        name: 'Львів, Львівська область',
        coordinates: {
            latitude: '49.8396830',
            longitude: '24.0297170'
        }
    }
}

const BronuiPlaces = () => {
    return (
      <>
          <NavLink
            className="hotel-index-places__image-block"
            to={generateStaticLinkToHotelsSearch(regions.kyiv)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/places/summer/kyiv.png"
              />
              <div className="hotel-index-places__image-text">Київ</div>
          </NavLink>

          <NavLink
            className="hotel-index-places__image-block"
            to={generateStaticLinkToHotelsSearch(regions.bukovel)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/places/summer/bukovel.png"
              />
              <div className="hotel-index-places__image-text">Буковель</div>
          </NavLink>

          <NavLink
            className="hotel-index-places__image-block "
            to={generateStaticLinkToHotelsSearch(regions.yaremche)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/places/summer/yaremche.png"
              />
              <div className="hotel-index-places__image-text">Яремча</div>
          </NavLink>

          <NavLink
            className="hotel-index-places__image-block"
            to={generateStaticLinkToHotelsSearch(regions.lviv)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/places/summer/lviv.png"
              />

              <div className="hotel-index-places__image-text">Львів</div>
          </NavLink>
      </>
    )
}


const VoyageHubPlaces =() => {
    return (
      <>
          <NavLink
            className="hotel-index-places__image-block"
            to={generateStaticLinkToHotelsSearch(regionsSudtirol.bolzano)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/voyagehub-places/1.png"
              />
              <div className="hotel-index-places__image-text">Bolzano</div>
          </NavLink>

          <NavLink
            className="hotel-index-places__image-block"
            to={generateStaticLinkToHotelsSearch(regionsSudtirol.merano)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/voyagehub-places/2.png"
              />
              <div className="hotel-index-places__image-text">Merano</div>
          </NavLink>

          <NavLink
            className="hotel-index-places__image-block "
            to={generateStaticLinkToHotelsSearch(regionsSudtirol.bressanone)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/voyagehub-places/3.png"
              />
              <div className="hotel-index-places__image-text">Bressanone</div>
          </NavLink>

          <NavLink
            className="hotel-index-places__image-block"
            to={generateStaticLinkToHotelsSearch(regionsSudtirol.brunico)}
          >
              <Image
                className="hotel-index-places__image"
                src="/images/index/voyagehub-places/4.png"
              />

              <div className="hotel-index-places__image-text">Brunico</div>
          </NavLink>
      </>
    )
}

const HotelIndexPlaces: FC = () => {
    const [t] = useTranslation();

    return (
        <div className="hotel-index-places">
            <Container>
              <div className="hotel-index-places__header">
                <h3 className="hotel-index-places__title">{t('Популярні напрямки')}</h3>
                <SenseBank/>
              </div>
                <div className="hotel-index-places__image-list">
                  {isBronui ? <BronuiPlaces/> : null}

                  {isSudtirol ? <VoyageHubPlaces/>: null}
                </div>
            </Container>
        </div>
    );
};

export default HotelIndexPlaces;
